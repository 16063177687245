<template>
  <div class="quesTion">
    <kind-choose-view
      :showKc="false"
      :type="12"
      @isOk="isOk"
      :testPaperType="testPaperType"
    />
    <div class="qustionList">
      <div class="llist">
        <div v-if="list && list.length > 0" class="llist">
          <div class="item" v-for="(item, index) in list" :key="index">
            <div class="itemmsg">
              <div class="itemimg">
                <img src="@/assets/img/homesixth/Group 36231.png" alt="" />
              </div>
              <div class="itmsg">
                <div class="twoEllipsis">{{ item.title }}</div>
                <div>共{{ item.number ? item.number : 0 }}份资料</div>
                <div v-if="item.isPurchase == 0 && userInfo">已购买</div>
                <div v-else>
                  <span class="price-icon">￥</span>{{ item.price }}
                </div>
              </div>
            </div>
            <div @click="toMaterialPage(item)" class="looktest">查看资料</div>
          </div>
        </div>
        <!-- 暂无课程 -->
        <div class="nothing" v-if="!list || list.length == 0">
          <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
          <div class="nothing-text">暂无内容哦~</div>
        </div>
        <el-pagination
          :total="total"
          :current-page="pageNum"
          :page-sizes="[6, 12, 18, 24]"
          :page-size="pageSize"
          :pager-count="3"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
      <div class="rlist">
        <div class="tuijian">
          <img src="@/assets/img/homesixth/Group 3611.png" alt="" />为您推荐
        </div>
        <div class="line"></div>
        <div class="tmsg" v-if="list && list.length > 0">
          <div
            @click="toMaterialPage(item)"
            v-for="(item, index) in list"
            v-if="index < 6"
            :key="index"
          >
            <div>{{ index + 1 }}</div>
            <span>{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import KindChooseView from '@/components/kindChooseViewsixthzl.vue'
import { DataClient } from '@/api/dataClient'
let dataClient = new DataClient()
import { getInfo, getToken } from '@/api/cookies'
import { OrderClient } from '@/api/orderClient'
let orderClient = new OrderClient()

export default {
  components: { KindChooseView },

  data() {
    return {
      list: {},
      search: {},
      pageSize: 6,
      pageNum: 1,
      total: 0,
      userInfo: null,

      testPaperType: null
    }
  },
  created() {
    this.testPaperType = this.$route.query.type
    this.userInfo = getInfo()
    // this.getList();
  },
  methods: {
    // 获取知识套餐列表
    getList() {
      dataClient
        .getPackageList(
          this.pageSize,
          this.pageNum,
          this.search.kind,
          this.search.zyId,
          this.search.type,
          this.userInfo ? this.userInfo.id : undefined
        )
        .then((res) => {
          this.list = res.rows
          this.total = res.total
          if (res.code) {
            this.list = []
            this.total = 0
          }
        })
    },

    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    // 筛选
    isOk(val) {
      this.search = val
      this.getList()
    },
    goOrder(item) {
      let token = getToken()
      if (token) {
        orderClient
          .addOrders(
            item.id,
            localStorage.getItem('userId'),
            item.title,
            5,
            item.title,
            item.kind
          )
          .then((res) => {
            if (res.code == 0) {
              /*   this.users = res.msg; */
              this.$router.push({
                name: '个人中心订单页',
                params: { orderNumber: res.msg }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
    // 点击图片跳转到详情页
    checkClick(item) {
      if (!this.userInfo) {
        this.$notify({
          title: '错误',
          message: '未登录，无法查看, 请先去登录',
          type: 'error',
          duration: 2000
        })
        return
      }
      this.$router.push(`/data/dataPackageDetail?packageId=${item.id}`)
    },
    /* 点击免费试看 */
    toMaterialPage(item) {
      if (this.userInfo) {
        this.$router.push({
          path: `/data/materialPage`,
          query: {
            id: item.id,
            title: item.title,
            schoolId: item.schoolId,
            isPurchase: item.isPurchase,
            num: item.price
          }
        })
      } else {
        this.$notify({
          title: '错误',
          message: '未登录，请去登录',
          type: 'error'
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.qustionList {
  width: 1220px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 10px;
  .llist {
    width: 820px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    .item {
      width: 756px;
      height: 132px;
      border-bottom: 1px solid #ececec;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .itemmsg {
        width: 403px;
        display: flex;
        .itemimg {
          width: 48px;
          height: 56px;
          margin-top: 24px;
          img {
            width: 48px;
            height: 56px;
          }
        }
        .itmsg {
          > div:nth-child(1) {
            width: 500px;
            height: 32px;
            line-height: 16px;
            // overflow: hidden;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-top: 24px;
            margin-left: 12px;
            // text-overflow: ellipsis;
            // white-space: nowrap;
          }
          > div:nth-child(2) {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-top: 12px;
            margin-left: 12px;
          }
          > div:nth-child(3) {
            font-size: 16px;
            font-family: DIN-Bold;
            font-weight: 400;
            color: #ff5e51;
            margin-top: 14px;
            margin-left: 12px;
            span {
              font-size: 12px;
            }
          }
        }
      }
      .looktest {
        width: 80px;
        height: 28px;
        background: #ffffff;
        border-radius: 14px 14px 14px 14px;
        opacity: 1;
        border: 1px solid #1061ff;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #1061ff;
        line-height: 28px;
        text-align: center;
        margin-top: 52px;
        cursor: pointer;
      }
    }
    .item:nth-child(1) {
      margin-top: 10px;
    }
  }
  .llists {
    width: 820px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    .item {
      width: 756px;
      height: 148px;
      border-bottom: 1px solid #ececec;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .itemmsg {
        width: 403px;
        display: flex;
        .itemimg {
          width: 48px;
          height: 56px;
          margin-top: 24px;
          img {
            width: 48px;
            height: 56px;
          }
        }
        .itmsg {
          > div:nth-child(1) {
            width: 373px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-top: 32px;
            margin-left: 12px;
          }
          > div:nth-child(2) {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-top: 12px;
            margin-left: 12px;
          }
          > div:nth-child(3) {
            font-size: 16px;
            font-family: DIN-Bold;
            font-weight: 400;
            color: #ff5e51;
            margin-top: 14px;
            margin-left: 12px;
            span {
              font-size: 12px;
            }
          }
        }
      }
      .looktest {
        width: 80px;
        height: 28px;
        background: #ffffff;
        border-radius: 14px 14px 14px 14px;
        opacity: 1;
        border: 1px solid #1061ff;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #1061ff;
        line-height: 28px;
        text-align: center;
        margin-top: 52px;
        cursor: pointer;
      }
    }
    .item:nth-child(1) {
      margin-top: 10px;
    }
  }
  .rlist {
    width: 380px;
    height: 355px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    padding-left: 32px;
    padding-top: 24px;
    .tuijian {
      display: flex;
      font-size: 18px;
      height: 24px;
      line-height: 20px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      img {
        width: 20px;
        height: 24px;
        // background: #ff9051;
        opacity: 1;
        margin-right: 8px;
      }
    }
    .line {
      width: 316px;
      height: 1px;
      background: #ececec;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      margin-top: 24px;
    }
    .tmsg {
      display: flex;
      flex-direction: column;
      > div {
        display: flex;

        height: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
        margin-top: 30px;
        cursor: pointer;

        div {
          width: 16px;
          height: 16px;
          background: #ff9051;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          line-height: 16px;
          text-align: center;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          margin-right: 8px;
        }
        span {
          width: 282px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      > div:nth-child(1) {
        margin-top: 21px;
      }
      > div:nth-child(4) {
        div {
          background: #999999;
        }
      }
      > div:nth-child(5) {
        div {
          background: #999999;
        }
      }
      > div:nth-child(6) {
        div {
          background: #999999;
        }
      }
    }
  }
}
/deep/ .el-pagination .active {
  background: #1061ff !important;
}
/deep/ .el-pagination {
  margin: 30px auto;
  width: 680px;
}
</style>
